
























































































import { defineComponent, ref, reactive } from '@vue/composition-api'
import axios from '@/service'
import { setToken, getToken, clearToken } from '@/utils'
export interface SnakerBarMsg {
  id?: string
  msg?: string
  enable?: boolean
  color?: string
}
export default defineComponent({
  name: 'signup',
  setup(_, { root: { $router } }) {
    const password = ref('')
    const account = ref('')
    const isLogin = ref(getToken())
    const snackbar = reactive<Array<SnakerBarMsg>>([
      {
        id: 'value-null',
        msg: '账号或密码不能为空',
        enable: false,
      },
      {
        id: 'reg-succ',
        msg: '登录成功',
        enable: false,
        color: 'success',
      },
      {
        id: 'reg-fail',
        msg: '登录失败，请稍后重试',
        enable: false,
        color: 'danger',
      },
    ])
    const gotoMenu = (ref: 'signup' | 'home') => {
      if (ref === 'signup') {
        $router.push('/Signup')
      } else if (ref === 'home') {
        $router.push('/')
      }
    }
    const findId = (snackbar: Array<SnakerBarMsg>, id: string) => {
      let index = -1
      snackbar.forEach((v, idx) => {
        if (v.id === id) {
          index = idx
        }
      })
      return index
    }
    const verForm = () => {
      if (account.value.trim() === '' || password.value.trim() === '') {
        const item = snackbar[findId(snackbar, 'value-null')]
        item.enable = true
        return false
      }
      return true
    }
    const handleLeave = () => {
      clearToken()
      gotoMenu('home')
    }
    const handleLogin = async () => {
      if (!verForm()) {
        return
      }
      const {
        data: { data, success, message },
      } = await axios.post('/auth/login', {
        account: account.value,
        password: password.value,
      })
      const token = data && data.token ? data.token : undefined
      if (success === true) {
        const item = snackbar[findId(snackbar, 'reg-succ')]
        item.enable = true
        setToken(token)
        setTimeout(() => {
          gotoMenu('home')
        }, 1000)
      } else {
        const item = snackbar[findId(snackbar, 'reg-fail')]
        if (message) {
          item.msg = message
        }
        item.enable = true
        password.value = ''
        account.value = ''
      }
    }
    return {
      gotoMenu,
      password,
      account,
      handleLogin,
      snackbar,
      isLogin,
      handleLeave,
    }
  },
})
